
window.addEventListener('cart.init', (e) => {
    var _ss = window._ss || [];
    let { cart } = e.detail;

    _ss.push(['_setTransaction', {
        'transactionID': `${ cart.id }`,
    }]);
});

window.addEventListener('cart.checkout', (e) => {
    var _ss = window._ss || [];
    let { cart, order } = e.detail;

    _ss.push(['_setTransaction', {
        'transactionID': `${ cart.id }`,
        'storeName': `${ window.env['APP_NAME'] }`,
        'total': `${ _.get(order, 'attributes.amount_billed', 0) }`,
        'tax': `${ _.get(order, 'attributes.gst', 0) }`,
        'shipping': `${ _.get(order, 'attributes.freight_total', 0) }`,
        'city': `${ _.get(order, 'attributes.s_city') }`,
        'state': `${ _.get(order, 'attributes.s_city') }`,
        'zipcode': `${ _.get(order, 'attributes.s_postcode') }`,
        'country': `${ _.get(order, 'attributes.s_country') }`,
        // the following params can be used for creating/updating
        // a contact in the context of the supplied transaction data.
        // if this data is omitted, the underlying contact/tracking data
        // associated with the visitors browser session is used automatically.
        'firstName' : `${ _.get(order, 'attributes.firstname') }`, // optional parameter
        'lastName' : `${ _.get(order, 'attributes.lastname') }`, // optional parameter
        'emailAddress' : `${ _.get(order, 'attributes.email') }`, // optional parameter
        // 'cartUrl': 'https://www.shopify.com/your/cart' // optional parameter
    }]);

    _ss.push(['_completeTransaction', {
        'transactionID': `${ cart.id }`
    }]);

});

window.addEventListener('cart.item.create', (e) => {
    var _ss = window._ss || [];
    let { cart, item } = e.detail;

    let categoryname = `${ _.chain(item)
        .get('product.categories', [])
        .first()
        .get('fullname')
        .value() }`;
    let productid = `${ _.get(item, 'price.code', '') }`;
    let productname = `${ _.get(item, 'price.description', '') }`;
    let productpath = `${ _.get(item, 'product.full_path', '') }`;
    let productimage = `${ _.get(item, 'product.default_image.link', '/asset/null.jpg') }`;
    let productsales = `${ _.chain(item)
        .get('item_price')
        .value()
    }`;
    let quantity = `${ _.get(item, 'quantity') }`;

    _ss.push(['_addTransactionItem', {
        'transactionID': `${ cart.id }`,
        'itemCode': productid,
        'productName': productname,
        'category': categoryname,
        'price': productsales,
        'quantity': quantity,
        'productURL': window.location.origin + '/' + productpath, // optional parameter
        'imagePath' : window.location.origin + productimage, // optional parameter
    }]);
});

window.addEventListener('cart.item.update', (e) => {
    var _ss = window._ss || [];
    let { cart, item } = e.detail;

    let categoryname = `${ _.chain(item)
        .get('product.categories', [])
        .first()
        .get('fullname')
        .value() }`;
    let productid = `${ _.get(item, 'price.code') }`;
    let productname = `${ _.get(item, 'price.description') }`;
    let productsales = `${ _.chain(item)
        .get('item_price')
        .value()
    }`;
    let quantity = `${ _.get(item, 'quantity') }`;

    _ss.push(['_addTransactionItem', {
        'transactionID': `${ cart.id }`,
        'itemCode': productid,
        'productName': productname,
        'category': categoryname,
        'price': productsales,
        'quantity': quantity,
        // 'productURL': 'https://yourcompany.com/olivemedium/tshirt', // optional parameter
        // 'imagePath' : 'https://yourcompany.com/olivemedium/tshirt.png' // optional parameter
    }]);
});

window.addEventListener('cart.item.delete', (e) => {
    var _ss = window._ss || [];
    let { cart, item } = e.detail;

    let productid = `${ _.get(item, 'price.code') }`;

    _ss.push(['_addTransactionItem', {
        'transactionID': `${ cart.id }`,
        'itemCode': productid,
        'quantity': -1,
        // 'productURL': 'https://yourcompany.com/olivemedium/tshirt', // optional parameter
        // 'imagePath' : 'https://yourcompany.com/olivemedium/tshirt.png' // optional parameter
    }]);
});


window.addEventListener('customer.enquiry', (e) => {
    // let store = e.detail.store;
});

window.addEventListener('customer.subscribe', (e) => {
    let params = e.detail.formParams;
    postToSharpSpring(params);
});

window.addEventListener('customer.register', (e) => {
    let params = e.detail.formParams;
    postToSharpSpring(params);
});

function postToSharpSpring(params){
    var __ss_noform = window.__ss_noform || [];

    var baseURI = 'https://app-3QNTECZDK6.marketingautomation.services/webforms/receivePostback/MzawMLE0MTQyBQA/';
    var endpoint = 'ba80d949-6999-4617-932f-302b15188872';
    var endpointURL = baseURI + endpoint + '/jsonp/';

    let ssTracking = Cookies.get('__ss_tk');
        params += '&trackingid__sb='+ssTracking;

    let url = endpointURL+'?'+params;

    window.jsonp(url, null, (err, data) => {
        console.log(err, data)
    });

    return true;
}